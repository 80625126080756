import React from 'react'
import About from '../components/About'
import Contact from '../components/Contact'
import Projects from '../components/Projects'

export default function Home() {
    return (
        <div >
            <About />
            <Projects />
            <Contact />
        </div>
    )
}
