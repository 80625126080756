import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Nav from './components/Nav';
import Home from './pages/Home';
import Footer from './components/Footer';
import ScrollToTop from './components/ScollToTop';

export default function App() {
  return (
    <div className='app-body'>
      <Router>
        <Nav />
          <Switch>
          <ScrollToTop>
            <Route path='/' exact component={Home}/>
            <Route path='/about' component={About}/>
            <Route path='/projects' component={Projects}/>
            <Route path='/contact' component={Contact}/>
          </ScrollToTop>
          </Switch>
        <Footer/>
      </Router>
    </div>
  )
}


