import { Link } from 'react-router-dom'

export default function Nav() {

    return (
        <div className='nav-container'>
            <Link className='logo-icon link' to='/'>
                <img className='logo-icon' alt='DS logo' src='./photos/2020_DS-Logo_Icon-Poppy.png'></img>
            </Link>
            <div className='nav-links-container'>
                <Link to='/' className='nav-link link'>About</Link>
                <Link to='/projects' className='nav-link link'>Projects</Link>
                <Link to='/contact' className='nav-link link'>Contact</Link>
                <a href='https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Dan_Seiders_Resume.pdf' className='nav-link link resume-nav-link'>Resume</a>
            </div>
        </div>

    )
}
