import React, { useState } from 'react'

export default function Contact() {
    const [email, setEmail] = useState({
        name: '',
        email: '',
        message: ''
    })

    const handleChange = (event) => {
        setEmail({ ...email, [event.target.id]: event.target.value})
    }

    return (
        <div className='contact-container'>
            <div className='contact-form-container'>
                <h1 className='contact-title'>Contact Me</h1>
                <form className='email-form' action='https://formsubmit.co/dseiders333@gmail.com' method='POST'>
                <input className='contact-input' type='text' name='name' id='name' onChange={handleChange} placeholder=' Name'></input>
                <input className='contact-input' type='email' name='email' id='email' onChange={handleChange} placeholder=' Email Address'></input>
                <textarea type='textarea' className='contact-input message' name='message' id='message' onChange={handleChange} placeholder=' Message'></textarea>
                <input className='contact-form-button btn-dark' type='submit' value='Send'></input>
                </form>
            </div>
        </div>
    )
}
