import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'


export default function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-icon-container'>
                <a href='mailto:dseiders333@gmail.com' className='footer-link'>
                    <FontAwesomeIcon icon={faEnvelopeSquare} className='icon'></FontAwesomeIcon>
                </a>
                <a href='https://www.linkedin.com/in/dan-seiders/' target='_blank' rel='noreferrer' className='footer-link'>
                    <FontAwesomeIcon icon={faLinkedin} className='icon'></FontAwesomeIcon>
                </a>
                <a href='https://github.com/danseiders' target='_blank' rel='noreferrer' className='footer-link'>
                    <FontAwesomeIcon icon={faGithub} className='icon'></FontAwesomeIcon>
                </a>
                <a href='https://www.instagram.com/danseiders3/' target='_blank' rel='noreferrer' className='footer-link'>
                    <FontAwesomeIcon icon={faInstagram} className='icon'></FontAwesomeIcon>
                </a>
            </div>
            <p className='footer-text'>© 2020 Dan Seiders</p>
        </div>
    )
}
