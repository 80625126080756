import React from 'react'

export default function Projects() {
    return (
        <div className='project-page-container'>
            <h1 className='title'>Projects</h1>
            <div className='podsquad-container project'>
                <h3 className='title-dark'>PODSquad</h3>
                <div className='project-img-container'>
                    <img src={'https://danseiders-portfolio.s3.us-east-2.amazonaws.com/PodSquad.png'} className='project-img podsquad' alt='POD Squad Screenshot'/>
                    <div className='project-overlay'>
                        <p className='text'>PodSquad is an app to help connect and pair users that have similar COVID-19 safety standards. Building this App taught me a lot about Node.js and Express. It also taught me about work flow, project management and how to set realistic goals for the build.<br></br><br></br>Built using HTML, CSS, JavaScript, Express, Node.js, MongoDB, Bootstrap and Font Awesome</p>
                        <div className='buttons'>
                            <a href='https://github.com/danseiders/thepodsquad' target='_blank' rel="noreferrer"><button className='button btn-light'>GitHub</button></a>
                            <a href='https://thepodsquad.herokuapp.com/sessions/new' target='_blank' rel="noreferrer"><button className='button btn-light'>Site</button></a>
                            <a href='https://github.com/danseiders/thepodsquad' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>GitHub</button></a>
                            <a href='https://thepodsquad.herokuapp.com/sessions/new' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>Site</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='battleship-container project'>
            <h3 className='title-dark'>BATTLESHIP</h3>
                <div className='project-img-container'>
                    <img src={'https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Battleship+Home.png'} className='project-img battleship' alt='Battleship app screenshot'/>
                    <div className='project-overlay'>
                        <p className='text'> Battleship seemed like a great game to build during a boot camp. I learned a lot about JQuery and using vanilla JavaScript for logic.<br></br><br></br>Built using HTML, CSS, JavaScript, JQuery, JQuery UI and JQuery UI Touch Punch.</p>
                        <div className='buttons'>
                            <a href='https://github.com/danseiders/battleship' target='_blank' rel="noreferrer"><button className='button btn-light'>GitHub</button></a>
                            <a href='https://danseiders.github.io/battleship/' target='_blank' rel="noreferrer"><button className='button btn-light'>Site</button></a>
                            <a href='https://github.com/danseiders/battleship' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>GitHub</button></a>
                            <a href='https://danseiders.github.io/battleship/' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>Site</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='farmstan-container project'>
                <h3 className='title-dark'>Farm Stan</h3>
                <div className='project-img-container'>
                    <img src={'https://danseiders-portfolio.s3.us-east-2.amazonaws.com/FarmStan+Home.png'} className='project-img farmstan' alt='Farm Stan App Screenshot'/>
                    <div className='project-overlay'>
                        <p className='text'>Farm Stan was a group project at General Assembly. Along with three other developers, I helped build the backend, built a lot of the front-end logic, and was responsible for Heroku deployment and GitHub repository management.<br></br><br></br>Built using React, Node.js, Express and MongoDB. </p>
                        <div className='buttons'>
                            <a href='https://github.com/danseiders/farmers_market_client' target='_blank' rel="noreferrer"><button className='button btn-light'>GitHub</button></a>
                            <a href='https://farm-stan-client.herokuapp.com/' target='_blank' rel="noreferrer"><button className='button btn-light'>Site</button></a>
                            <a href='https://github.com/danseiders/farmers_market_client' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>GitHub</button></a>
                            <a href='https://farm-stan-client.herokuapp.com/'  target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>Site</button></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='crew-you-container project'>
                <h3 className='title-dark'>Crew You</h3>
                <div className='project-img-container'>
                    <img src={'https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Crew+You+Home.png'} className='project-img crew-you' alt='Crew You App Screenshot'/>
                    <div className='project-overlay'>
                        <p className='text'>Crew You is a social app for touring and local crew members in the music industry. Connect, talk rates and find gigs. Building this taught me how quickly Python can be picked up. It was a blast to work in a new language.  <br></br><br></br>Built using React, Python, Flask and PostgreSQL. </p>
                        <div className='buttons'>
                            <a href='https://github.com/danseiders/crewyou-client' target='_blank' rel="noreferrer"><button className='button btn-light'>GitHub</button></a>
                            <a href='https://main.d1tgaf2znj9a79.amplifyapp.com/' target='_blank' rel="noreferrer"><button className='button btn-light'>Site</button></a>
                            <a href='https://github.com/danseiders/crewyou-client' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>GitHub</button></a>
                            <a href='https://main.d1tgaf2znj9a79.amplifyapp.com/' target='_blank' rel="noreferrer"><button className='button btn-dark btn-hide'>Site</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
