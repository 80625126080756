import { Component } from 'react'

export default class ScollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}
