import React, { useState, useEffect } from 'react'

export default function About() {
    const [word, setWord] = useState('Python')
    const wordArr = ['HTML', 'CSS', 'JavaScript', 'Python', 'React', 'Node', 'Express', 'Terraform', 'Puppet', 'SQL', 'AWS', 'Azure']

    const cycleWords = () => {
        const currentWord = wordArr[Math.floor(Math.random() * wordArr.length)]
        if (currentWord !== word){
            setTimeout(() => {setWord(currentWord)}, 1000)
        } else {
            cycleWords()
        }
    }

    useEffect(() => {
        cycleWords()
    })

        return (
            <div className='main-title-container'>
                <div className='main-title-hrz'>
                    <div className='main-title-bot-div'>
                        <img className='headshot' alt='Dan'src='https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Dan_Seiders_Headhot.jpg'></img>
                        <div className='main-title-right'>
                            <img className='logo-full' alt='Dan Seiders logo' src='./photos/2020_DS-Logo_Full-Poppy-Bone.png'></img>
                            <img className='headshot-two' alt='Dan' src='https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Dan_Seiders_Headhot.jpg'></img>
                            <p className='about-body'>Hello! <br></br> I'm Dan, a Full Stack Developer with an obsession for writing clean code. Currently working as a DevOps engineer for NBCUniversal and previously as a Data Engineer. I can find a solution to any problem and lately it's been with <span className='word'>{word}.</span><br></br><br></br></p>
                            <a href='https://danseiders-portfolio.s3.us-east-2.amazonaws.com/Dan_Seiders_Resume.pdf' className='resume-link btn-dark show'>Check out my resume</a>
                        </div>
                    </div>
                    <div className='tech-div'>
                        <p><span className='tech'>Technologies: </span>JavaScript, Python, React, Node, Terraform, Puppet, Flask, Express, AWS, Azure, SQL, Dante, Pro Tools, Logic, Ableton Live  </p>
                    </div>
                </div>
            </div>
        )
}
